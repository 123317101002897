import React from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n } from "@lingui/react";
import '../Landing/Groups.scss';
import _ from 'lodash';

class ActionData extends React.Component{

    constructor(props){
        super(props);
    }
    render(){
        const props = this.props;
        const idx = props.index
        // console.log(props);
            if(props.subTasks[idx] && props.subTasks[idx].action_command){
                // console.log(props.subTasks[idx].action_data);
                let qr_hint = '';
                if(props.subTasks[idx].action_command === 'Ctrl+A'){
                    qr_hint = 'jobs/renderAsset:::<URL where the assets reside>:::<3D asset file name.obj>:::<2d asset file name>'
                }else{
                    qr_hint = 'jobs/getIIOTData/Temperature=230 centigrade'
                }
                switch(props.subTasks[idx].action_command){
                    case "Ctrl+A":
                    case "Ctrl+Q":
                        return (
                            <I18n>
                                {({ i18n }) => 
                                    <>
                                        {props.subTasks[idx].action_data.map((val, j)=> {
                                            val = JSON.parse(val);
                                            return(
                                            <Row key={j} className="mt-1">
                                                <Col className="input-wrapper">
                                                    <input
                                                    type="text"
                                                    name={i18n._(t`qr_code`)+ idx+'_'+j}
                                                    data-id={j}
                                                    id={`qr_code-`+ idx +'_'+j}
                                                    placeholder={i18n._(t` QR code url`)}
                                                    className="signup-input-field"
                                                    onChange={props.onChangeTaskForm({fieldName:'qr_code',index:idx})}
                                                    value={val["qr_code"] || ''}
                                                    disabled={props.isSubTaskReadOnly}
                                                    autoComplete="off"
                                                    />
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={`qr_code-`+ idx +'_'+j}>
                                                        {qr_hint}
                                                    </UncontrolledTooltip>
                                                { props.validator.message(i18n._(t`qr_code`)+ idx+'_'+ j, val["qr_code"], `required|checkScanQRFormat:${props.subTasks[idx].action_command}`) }
                                                </Col>
                                                {/*<div className="col-md-1 pl-0 mt-2 pointer">
                                                    <Row>
                                                        <div className="col-md-6">
                                                             {props.subTasks[idx].action_data.length === j+1 ?
                                                                <i className="fas fa-plus-circle" aria-hidden="true"
                                                                onClick={(e)=> props.addActionData({index:idx, subIndex: j})}></i>
                                                            : null
                                                            }
                                                        </div>
                                                        <div className="col-md-6 pl-0">
                                                            {/* <i className="fas fa-trash red-trash" aria-hidden="true"
                                                            onClick={(e)=> props.deleteActionData({index:idx, subIndex: j})}></i> 
                                                        </div>
                                                    </Row>
                                                </div>*/}
                                            </Row>
                                            )
                                        })
                                        }
                                    </>
                                }
                            </I18n>
                        )
                    case "Ctrl+E":
                            return (
                                <I18n>
                                    {({ i18n }) => 
                                        <>
                                            {props.subTasks[idx].action_data.map((val, j)=> {
                                                val = JSON.parse(val);
                                                return(
                                                    <>
                                                        <Row key={j} className="mt-1">
                                                            <div className="input-wrapper col-4">
                                                                <input
                                                                type="text"
                                                                name={i18n._(t`parameter_name`)+ idx+'_'+j}
                                                                data-id={j}
                                                                placeholder={i18n._(t`Parameter Name`)}
                                                                className="signup-input-field"
                                                                onChange={props.onChangeTaskForm({fieldName:'parameter_name',index:idx})}
                                                                value={val["parameter_name"] || ''}
                                                                disabled={props.isSubTaskReadOnly}
                                                                />
                                                                { props.validator.message(i18n._(t`parameter_name`)+ idx+'_'+ j, val["parameter_name"], 'required') }
                                                            </div>
                                                            <Col className="input-wrapper">
                                                                <input
                                                                type="text"
                                                                name={i18n._(t`type`)+ idx+'_'+j}
                                                                data-id={j}
                                                                placeholder={i18n._(t`Type`)}
                                                                className="signup-input-field"
                                                                onChange={props.onChangeTaskForm({fieldName:'type',index:idx})}
                                                                defaultValue={i18n._(t`Number`)}
                                                                disabled={true}
                                                                // value={val["type"] || 'Number'}
                                                                // disabled={!props.isSubTaskReadOnly}
                                                                />
                                                                {/* { props.validator.message(i18n._(t`type`)+ idx+'_'+ j, val["type"], 'required') } */}
                                                            </Col>
                                                            <div className="input-wrapper col-3">
                                                                <input
                                                                type="text"
                                                                name={i18n._(t`unit`)+ idx+'_'+j}
                                                                data-id={j}
                                                                placeholder={i18n._(t`Unit`)}
                                                                className="signup-input-field"
                                                                onChange={props.onChangeTaskForm({fieldName:'unit',index:idx})}
                                                                value={val["unit"] || ''}
                                                                disabled={props.isSubTaskReadOnly}
                                                                />
                                                                { props.validator.message(i18n._(t`unit`)+ idx+'_'+ j, val["unit"], 'required') }
                                                            </div>
                                                            {/* <div className="col-1 pl-0 mt-2 pointer">
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        {props.subTasks[idx].action_data.length === j+1 ?
                                                                            <i className="fas fa-plus-circle" aria-hidden="true"
                                                                            onClick={(e)=> props.addActionData({index:idx, subIndex: j})}></i>
                                                                        : null
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-6 pl-0">
                                                                                <i className="far fa-trash-alt red-trash" aria-hidden="true"
                                                                        onClick={(e)=> props.deleteActionData({index:idx, subIndex: j})}></i>
                                                                    </div>
                                                                </Row>
                                                            </div> */}
                                                        </Row>
                                                        {/* TZ-1146 */}
                                                        <Row key={j+1} className="mt-3">
                                                            <Col className="input-wrapper">
                                                                <input
                                                                type="text"
                                                                name={i18n._(t`minimum_range`)+ idx+'_'+j}
                                                                data-id={j}
                                                                placeholder={i18n._(t`Minimum Range`)}
                                                                className="signup-input-field"
                                                                onChange={props.onChangeTaskForm({fieldName:'minimum_range',index:idx})}
                                                                value={val["minimum_range"] || ''}
                                                                disabled={props.isSubTaskReadOnly}
                                                                />
                                                                { props.validator.message(i18n._(t`minimum_range`)+ idx+'_'+ j, val["minimum_range"], 'required|numeric|min:0,num') }
                                                            </Col>                                                    
                                                            <Col className="input-wrapper">
                                                                <input
                                                                    type="text"
                                                                    name={i18n._(t`maximum_range`)+ idx+'_'+j}
                                                                    data-id={j}
                                                                    placeholder={i18n._(t`Maximum Range`)}
                                                                    className="signup-input-field"
                                                                    onChange={props.onChangeTaskForm({fieldName:'maximum_range',index:idx})}
                                                                    value={val["maximum_range"] || ''}
                                                                    disabled={props.isSubTaskReadOnly}
                                                                />
                                                                { props.validator.message(i18n._(t`maximum_range`)+ idx+'_'+ j, val["maximum_range"], 'required|numeric|min:0,num') }
                                                            </Col>
                                                            {/* TZ-1407 */}
                                                            <Col className="input-wrapper">
                                                                <input
                                                                    type="text"
                                                                    name={i18n._(t`group`)+ idx}
                                                                    data-id={idx}
                                                                    placeholder={i18n._(t`Group`)}
                                                                    className="signup-input-field"
                                                                    onChange={props.onChangeJobForm("group_name")}
                                                                    value={props.subTasks[idx]["group_name"] || ''}
                                                                    disabled={props.isSubTaskReadOnly}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )
                                            })
                                            }
                                        </>
                                    }
                                </I18n>
                            )
                        case "Ctrl+P":
                            //TP-2480
                            return(
                                <>
                                    <label htmlFor="take_image" className="ml-2 h4">
                                        <input className="checkbox" type="checkbox" name="take_image" checked={props.subTasks[idx]["take_image"]} 
                                            data-id={idx} onChange={props.onChangeJobForm('take_image')} disabled={props.isSubTaskReadOnly}>
                                        </input>
                                        <Trans>Take Image</Trans>
                                    </label>                            
                                </>
                            )
                    default:
                        return '';
                }

        }else{
            return '';
        }
    }
}

export default ActionData;